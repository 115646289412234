body {
  background: url('./Assets/home.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -o-background-attachment: fixed;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.formContainer {
  width: 60%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255,255,255,0.95);
  border-radius: 20px;
}
.formBox {
  padding: 20px 20px 70px 20px;
  margin: 0 auto;
  width: 90%;
}
.formContent {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
}
.formAddressContent{
  margin: 0 auto;
  margin-top: 10px;
  /* display: flex; */
}
.formButtons {
  width: 31%;
  height: 40px;
  border: 1px solid #f59842;
  margin: 8px;
  background-color: white;
  cursor: pointer;
  outline: none;
}
.buttonContainer {
  width: 100%;
  height: 20px;
  margin: 0 auto;
  margin-top: 50px;
}
.prevButton {
  width: 100px;
  height: 40px;
  color: white;
  background-color: #424242;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
}
.nextButton {
  float: right;
  width: 100px;
  height: 40px;
  background-color: #f59842;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  -webkit-background-color: #f59842;
  -moz-background-color: #f59842;
  -o-background-color: #f59842;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-appearance: none;
}
.containerWidth {
  width: 100%;
  height: auto;
}
.formNumberButtons {
  width: 15%;
  height: 40px;
  border: 1px solid #f59842;
  margin: 6px;
  background-color: white;
  cursor: pointer;
  outline: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 45px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 35px;
  width: 35px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f59842;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f59842;
}

input:checked + .slider:before {
  -webkit-transform: translateX(57px);
  -ms-transform: translateX(57px);
  transform: translateX(57px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.HighlightedButtonStyle{
  width: 31%;
  height: 40px;
  border: 1px solid #f59842;
  margin: 8px;
  background-color: #f59842;
  cursor: pointer;
  outline: none;
  color:white;
  font-weight: 900;
}
.HighlightedNumberButtonStyle{
  width: 15%;
  height: 40px;
  border: 1px solid #f59842;
  margin: 6px;
  background-color: #f59842;;
  cursor: pointer;
  color:white;
  font-weight: 900;
  outline: none;
}
.HomeAddressInput{
  outline: none;
  border: 1px solid black;
  padding: 15px 10px;
  width:90%;
}
.HomeAddressInput:focus{
  border: 1px solid #f59842;
}
.HomeAddressInputContainer{
  width: 60%;
}
.stateInput{
  outline: none;
  border: 1px solid black;
  padding: 15px 10px;
  width:80%;
}
.cityInputContainer{
  width:40%;
}
.stateInputContainer{
  width:35%;
}
.zipInputContainer{
  width:25%;
}
.agentInputContainer{
  width:40%;
}
.zipInput{
  width:75%;
  outline: none;
  border: 1px solid black;
  padding: 15px 10px;
}
.zipInput:focus{
  border: 1px solid #f59842;
}
.formAddressRow{
  width:100%;
  display: flex;
}
.formRow{
  width:100%;
  display: flex;
}
.userInputContainer{
  width:50%
}
.userInput{
  outline: none;
  border: 1px solid black;
  padding: 15px 10px;
  width:85%;
}
.userInput:focus{
  border: 1px solid #f59842;
}
.ThankTexts{
  text-align: center;
  color: #f59842;
  margin-top: 50px;
}
.formTitle {
 text-align: center;
 color:#f59842;
 font-weight: 900;;
}
.formTexts{
  font-weight: 900;
  text-align: center;
  color:#f59842;
}
.textContainer{
  width: 90%;
  margin: 0 auto;
}
.logo{
  width:200px;
}
.logoContainer{
  width:100%;
  height: 100px;;
}
@media only screen and (max-width: 1268px) {
  .formButtons {
    width: 30%;
  }
  .HighlightedButtonStyle{
    width: 30%;
  }
}
@media only screen and (max-width: 892px) {
  .formContainer{
    width:95%;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .HighlightedButtonStyle{
    width: 95%;
  }
  .formContainer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.90);
    border-radius: 20px;
  }
  .logo{
    margin: 0 auto;
  }
  .formButtons{
    width: 95%;
  }
  .formAddressRow{
    width:100%;
    display: block;
  }
  .HomeAddressInputContainer{
    width:95%;
  }
  .cityInputContainer{
    width:95%;
  }
  .HomeAddressInput{
    width:100%
  }
  .formAddressContent{
    display: block;
  }
  .stateInputContainer{
    width:95%;
  }
  .zipInputContainer{
    width:100%;
  }
  .zipInput{
    width:95%;
  }
  .agentInputContainer{
    width:90%;
  }
  .userInputContainer{
    width:100%;
  }
  .formRow{
    display: block;
    width:100%;
  }
  .userInput{
    width:95%;
  }
  .stateInput{
    width: 100%;
  }
}
